// src/AgentPage.tsx
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import hero from './hero.svg';
import './App.css';

import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import {
  ChatComposite,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationChatAdapter
} from '@azure/communication-react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';


const AgentPage: React.FC = () => {
  // Arguments that would usually be provided by your backend service or
  // (indirectly) by the user.
  const serviceArgs = useAzureCommunicationServiceArgs();

  const [enrichments, setEnrichments] = useState({ summarize: "", actions: "", highlights: "", followup: "" });

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios.get(`/api/threadenrichments?threadId=${serviceArgs.threadId}`)
        .then(response => {
          const { data } = response;
          setEnrichments(data);
        })
        .catch(error => {
          console.error('Error fetching enrichments:', error);
        });
    }, 5000); // fetches every 5 seconds

    // cleanup function, clears the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [serviceArgs.threadId]); // dependency array updated

  // A well-formed token is required to initialize the chat and calling adapters.
  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(serviceArgs.token);
    } catch {
      console.error('Failed to construct token credential');
      return undefined;
    }
  }, [serviceArgs.token]);

  // Memoize arguments to `useAzureCommunicationChatAdapter` so that
  // a new adapter is only created when an argument changes.
  const chatAdapterArgs = useMemo(
    () => ({
      endpoint: serviceArgs.endpointUrl,
      userId: fromFlatCommunicationIdentifier(serviceArgs.userId) as CommunicationUserIdentifier,
      displayName: serviceArgs.displayName,
      credential,
      threadId: serviceArgs.threadId,
    }),
    [serviceArgs.endpointUrl, serviceArgs.userId, serviceArgs.displayName, credential, serviceArgs.threadId]
  );
  const chatAdapter = useAzureCommunicationChatAdapter(chatAdapterArgs);
  const hashId = serviceArgs.thread_hash;

  if (!!chatAdapter) {
    return (
      <div>
        <div id="header">
          <h1 style={{ ...H1ContainerStyle }}>Customer Service</h1>
        </div>
        <div id="conversation">
          <div className="header">
            <h2><span>Icon</span>Conversation</h2>
          </div>

          <div style={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
            <div style={{ ...containerStyle, height: '98vh' }}>
              <ChatComposite adapter={chatAdapter} options={{ topic: false }} />
            </div>
            {/* <div style={{ ...containerStyle, height: '5vh' }}>
                Continue with voice or text with the code <b>{hashId}</b> at <b>1-844-231-5460</b>
              </div> */}
          </div>
        </div>
        <div id="copilot">

          <div className="header">
            <h2 style={{ ...H2ContainerStyle }}><span>Icon</span>Copilot</h2>
          </div>

          <div className="content">

            <div id="summary" className="section">
              <h3 style={{ ...H3ContainerStyle }}>Summary</h3>
              <ul>
                <li>{enrichments.summarize}            </li>
              </ul>
            </div>

            <div id="highlights" className="section">
              <h3 style={{ ...H3ContainerStyle }}>Highlights</h3>
              <div dangerouslySetInnerHTML={{ __html: enrichments.highlights }} />
            </div>

            <div id="actions" className="section">
              <h3 style={{ ...H3ContainerStyle }}>actions</h3>
              <div dangerouslySetInnerHTML={{ __html: enrichments.actions }} />
            </div>

            <div id="customer_communication" className="section">
              <h3 style={{ ...H3ContainerStyle }}>Customer Communication</h3>
              <ul>
                <li>              <div dangerouslySetInnerHTML={{ __html: enrichments.followup }} />           </li>
              </ul>


            </div>

          </div>
        </div>
      </div>

    );
  }
  if (credential === undefined) {
    return <h3>Failed to construct credential. Provided token is malformed.</h3>;
  }
  return <h3>Initializing...</h3>;
}


const containerStyle: CSSProperties = {
  width: '100%',
  height: '100%'

};

const H1ContainerStyle: CSSProperties = {
  fontSize: '1.4em',
  color: '#FFF',
  lineHeight: '0px',
};

const H2ContainerStyle: CSSProperties = {
  fontSize: '1.3em',
  color: '#0345BF',
  lineHeight: '32px'
};

const H3ContainerStyle: CSSProperties = {
  fontSize: '.9em',
  textTransform: 'uppercase',
  lineHeight: '10px'
};

function useAzureCommunicationServiceArgs() {
  const [serviceArgs, setServiceArgs] = useState({
    endpointUrl: '',
    userId: '',
    token: '',
    displayName: '',
    groupId: '',
    threadId: '',
    thread_hash: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/jointhread');
        setServiceArgs(response.data);
      } catch (error) {
        console.error('Error fetching service args:', error);
      }
    };
    fetchData();
  }, []);

  return serviceArgs;
}


export default AgentPage;
