import React, { CSSProperties, useEffect, useMemo, useRef, useState, ChangeEvent, MouseEvent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import hero from './hero.svg';
import './App.css';

import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import {
  ChatComposite,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationChatAdapter
} from '@azure/communication-react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import AgentPage from './AgentPage';
import { threadId } from 'worker_threads';

/**
 * Display name for the local participant.
 * In a real application, this would be part of the user data that your
 * backend services provides to the client application after the user
 * goes through your authentication flow.
 */
const DISPLAY_NAME = 'Sandy Jones';
const HomePage: React.FC = () => {
  // Arguments that would usually be provided by your backend service or
  // (indirectly) by the user.
  const serviceArgs = useAzureCommunicationServiceArgs();

  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleSMSButtonClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await axios.post('/api/connectchannel', {
        type: 'sms',
        phone_number: phoneNumber,
        thread_id: serviceArgs.threadId
      });
    } catch (error) {
      console.error('Error connecting channel:', error);
    }
  };

  const handleCallButtonClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await axios.post('/api/connectchannel', {
        type: 'voice',
        phone_number: phoneNumber,
        threadId: serviceArgs.threadId

      });
    } catch (error) {
      console.error('Error connecting channel:', error);
    }
  };

  // A well-formed token is required to initialize the chat and calling adapters.
  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(serviceArgs.token);
    } catch {
      console.error('Failed to construct token credential');
      return undefined;
    }
  }, [serviceArgs.token]);

  // Memoize arguments to `useAzureCommunicationChatAdapter` so that
  // a new adapter is only created when an argument changes.
  const chatAdapterArgs = useMemo(
    () => ({
      endpoint: serviceArgs.endpointUrl,
      userId: fromFlatCommunicationIdentifier(serviceArgs.userId) as CommunicationUserIdentifier,
      displayName: serviceArgs.displayName,
      credential,
      threadId: serviceArgs.threadId,
    }),
    [serviceArgs.endpointUrl, serviceArgs.userId, serviceArgs.displayName, credential, serviceArgs.threadId]
  );
  const chatAdapter = useAzureCommunicationChatAdapter(chatAdapterArgs);
  const hashId = serviceArgs.thread_hash;

  if (!!chatAdapter) {
    return (
      <div>
        <div id="header">
          <h1 style={{ ...H1ContainerStyle }}>Customer Service</h1>

        </div>
        <div id="conversation" className="expanded">
          <div className="header">
            <h2><span>Icon</span>Conversation</h2>
          </div>

          <div className="banner">
            <p>Continue this conversation via call or text: </p>
            <input
              name="phone_number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Enter your phone number"
            />

            <button name="call_button" onClick={handleCallButtonClick}>
              Call me
            </button>
            <button name="sms_button" onClick={handleSMSButtonClick}>
              Text me
            </button>
          </div>
          <div>
            <div style={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
              <div style={{ ...containerStyle, height: '95vh' }}>
                <ChatComposite adapter={chatAdapter} options={{ topic: false}} />
              </div>
              {/* <div style={{ ...containerStyle, height: '5vh' }}>
                Continue with voice or text with the code <b>{hashId}</b> at <b>1-844-231-5460</b>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (credential === undefined) {
    return <h3>Failed to construct credential. Provided token is malformed.</h3>;
  }
  return <h3>Initializing...</h3>;
}

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/agent">
          <AgentPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>
  );
}
const containerStyle: CSSProperties = {
  // border: 'solid 0.125rem grey',
width: '100%',
height: '100%'
};

const H1ContainerStyle: CSSProperties = {
  fontSize: '1.4em',
  color: '#FFF',
  lineHeight: '0px',
};

function useAzureCommunicationServiceArgs() {
  const [serviceArgs, setServiceArgs] = useState({
    endpointUrl: '',
    userId: '',
    token: '',
    displayName: '',
    groupId: '',
    threadId: '',
    thread_hash: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/createthread');
        setServiceArgs(response.data);
      } catch (error) {
        console.error('Error fetching service args:', error);
      }
    };
    fetchData();
  }, []);

  return serviceArgs;
}

export default App;
